
import {Vue, prop} from 'vue-class-component';
import Problem from "@/scripts/Problem";
import NumberingSystem from "@/scripts/1/NumberingSystem";
import Recursion from "@/scripts/1/Recursion";

const problems = [new NumberingSystem(), new Recursion()]

class ProblemProps
{
    id = prop<string>({required: true})
}

export default class ProblemView extends Vue.with(ProblemProps)
{
    question = ''
    prob!: Problem

    answer = ''
    revealAns = false

    lastId = -1;
    created() { this.updateQuestion() }
    beforeUpdate() { if (this.lastId != +this.id) this.updateQuestion() }

    updateQuestion()
    {
        const id = +this.id
        this.lastId = id
        if (id >= problems.length) alert("The problem ID in your url doesn't exist 🤔")

        this.prob = problems[id];
        this.prob.newQuestion();
        this.question = this.prob.question

        this.answer = ''
        this.revealAns = false
    }

    checkAns()
    {
        if (this.prob.checkAnswer(this.answer)) alert('Yay! Correct!');
        else alert('Nope');
    }

    reveal()
    {
        this.revealAns = !this.revealAns;
    }
}
