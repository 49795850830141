<template>
    <div class="home" style="text-align: center">
        <img alt="Vue logo" src="../assets/logo.png">
        <h1>ACSL Practice Site</h1>
        <div>
            There's nothing decorative for this site. It's just a practice problem site specifically for the ACSL topics made by <a href="https://github.com/hykilpikonna">Hykilpikonna</a>.
        </div>
    </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'; // @ is an alias to /src

@Options({components: {}})
export default class Home extends Vue
{

}
</script>
